<!--数据-->
<template>
    <div class="container data-box newBox">
        <div class="headerBox">
            <div class="headerLeft">
                <div class="boxStyle"></div>
                <div>固废资源化技术</div>
            </div>
            <div class="searchBox">
                <div>模糊查询：</div>
                <div class="searchWidth">
                    <el-input
                      placeholder="请输入内容"
                      v-model="searchModel.searchValue"
                      clearable>
                    </el-input>
                </div>
                <div class="searchStyle" @click="selectData">查询结果</div>
            </div>
        </div>
        
        <div class="centerSelect">
            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废来源</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in sources " :key="index">
                        <div class="gouImg" v-if="item.active">
                            <img src="../../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText" :class="{active:item.active}" @click="clickCategory(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>
            
            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废大类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in wasteBigType " :key="index">
                        <div class="gouImg" v-if="item.active1">
                            <img src="../../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText" :class="{active1:item.active1}" @click="clickbig(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>
            
            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废小类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in wasteSmallType " :key="index">
                       <div class="gouImg" v-if="item.active2">
                           <img src="../../assets/img/gou.png" alt="">
                       </div>
                       <div class="itemText" :class="{active2:item.active2}" @click="smallCK(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="emptyAndInquire">
            <div class="qKBox">
                <div class="qKImg">
                    <img src="../../assets/img/qk.png" alt="">
                </div>
                <div class="qkTEXT">清空条件</div>
            </div>
        </div>
         
         <div class="emptyAndInquire">
             <div class="InquireBOX">
                 <div class="qKImg">
                     <img src="../../assets/img/cxb.png" alt="">
                 </div>
                 <div class="cXTEXT">查询结果</div>
             </div>
         </div>
         
         
         <div class="CXresult">
             <p class="EnglishText">- QUERY RESULTS -</p>
             <p class="resultText">查询结果</p>
         </div>
         
         <div class="resultListBox">
             <div class="resultListTop">
                 <div>固废类型</div>
                 <div>资源化技术名称</div>
                 <div>综合影响</div>
                 <div>资源影响</div>
                 <div>环境影响</div>
                 <div>经济影响</div>
                 <div>社会影响</div>
                 <div>操作</div>
             </div>
             <div class="resultListCenter">
                <div class="resultListCenterItem" v-for="(item,index) in tableData " :key="index">
                    <div>{{item.wasteName}}</div>
                    <div>资源化技术名称</div>
                    <div>53.86</div>
                    <div>14.45</div>
                    <div>12.82</div>
                    <div>21.59</div>
                    <div>5</div>
                    <div class="textStyletwo" @click="viewDetail(item.id)">详情</div>
                </div>
             </div>
             <div class="resultListButtom">
                 <div>
                     <el-pagination
                       background
                      layout="total, prev, pager, next"
                      :total="total"
                      :page-count="pageCount" 
                      :page-size="pageSizes" 
                      :current-page="currentPage"
                      @current-change="handleCurrentChange"
                       >
                     </el-pagination>
                 </div>
             </div>
         </div>
         
         <el-dialog title="详情" :visible.sync="view.detailVisible" width="60%" class="detailDialog">
             <el-row :gutter="20">
                 <el-col span="6">
                     固废名称: {{ this.dialogData.wasteName }}
                 </el-col>
                 <el-col span="6">
                     年份: {{ this.dialogData.year }}
                 </el-col>
                 <el-col span="6">
                     区域: {{ this.dialogData.areaName }}
                 </el-col>
                 <el-col span="6">
                     产生量(吨): {{ this.dialogData.wasteGeneration }}
                 </el-col>
             </el-row>
             <el-row :gutter="20">
                 <el-col span="6">
                     颜色: {{ this.dialogData.color }}
                 </el-col>
                 <el-col span="6">
                     形态: {{ this.dialogData.form }}
                 </el-col>
                 <el-col span="6">
                     粒径（um）: {{ this.dialogData.particleSize }}
                 </el-col>
                 <el-col span="6">
                     渗透系数（cm/s）: {{ this.dialogData.permeabilityCoeffcient }}
                 </el-col>
             </el-row>
             <el-row :gutter="20">
                 <el-col span="6">
                     二水硫酸钙含量: {{ this.dialogData.csd }}
                 </el-col>
                 <el-col span="6">
                     放射性: {{ this.dialogData.radioactivity }}
                 </el-col>
                 <el-col span="6">
                     ph: {{ this.dialogData.ph }}
                 </el-col>
                 <el-col span="6">
                     二氧化硅: {{ this.dialogData.siliconDioxide }}
                 </el-col>
             </el-row>
             <el-row :gutter="20">
                 <el-col span="6">
                     氧化铝: {{ this.dialogData.alumina }}
                 </el-col>
                 <el-col span="6">
                     氧化铁: {{ this.dialogData.ferricOxide }}
                 </el-col>
                 <el-col span="6">
                     氧化钙: {{ this.dialogData.calciumOxide }}
                 </el-col>
                 <el-col span="6">
                     三氧化硫: {{ this.dialogData.sulfurTrioxide }}
                 </el-col>
             </el-row>
             <el-row :gutter="20">
                 <el-col span="6">
                     结晶水: {{ this.dialogData.crystalWaster }}
                 </el-col>
                 <el-col span="6">
                     氧化镁: {{ this.dialogData.magensiumOxide }}
                 </el-col>
             </el-row>
             <!--            <el-row :gutter="20">
                       <el-col span="6">
                           利用量: {{this.dialogData.wasteRecovery}}
                       </el-col>
                       <el-col span="6">
                           生活垃圾处置量: {{this.dialogData.wasteDisposal}}
                       </el-col>
                       <el-col span="6">
                           贮存量: {{this.dialogData.wasteStorage}}
                       </el-col>
                       <el-col span="6">
                           物理组成: {{this.dialogData.composition}}
                       </el-col>
                   </el-row>
                   <el-row :gutter="20">
                       <el-col span="6">
                           密度: {{this.dialogData.wasteDensity}}
                       </el-col>
                       <el-col span="6">
                           含水率: {{this.dialogData.moistureContent}}
                       </el-col>
                       <el-col span="6">
                           关键物质组分: {{this.dialogData.keyElement}}
                       </el-col>
                       <el-col span="6">
                           毒害物质组分: {{this.dialogData.toxicElement}}
                       </el-col>
                   </el-row>
                   <el-row :gutter="20">
                       <el-col span="6">
                           灰分: {{this.dialogData.ashContent}}
                       </el-col>
                       <el-col span="6">
                           高位热值: {{this.dialogData.highHeatValue}}
                       </el-col>
                       <el-col span="6">
                           地位热值: {{this.dialogData.lowHeatValue}}
                       </el-col>
                       <el-col span="6">
                           有机碳: {{this.dialogData.organicCarbon}}
                       </el-col>
                   </el-row>
                   <el-row :gutter="20">
                       <el-col span="6">
                           挥发性物质: {{this.dialogData.volatileMatter}}
                       </el-col>
                       <el-col span="6">
                           生物降解铀组分: {{this.dialogData.biodegradableFraction}}
                       </el-col>
                       <el-col span="6">
                           机械化: {{this.dialogData.mechanization}}
                       </el-col>
                   </el-row>-->
             <span slot="footer" class="dialog-footer">
                 <!--        <el-button @click="view.dialogVisible = false">关 闭</el-button>-->
             </span>
         </el-dialog>
    </div>
</template>
<script>
    import ui from "../../libs/ui"
    import {
        getWasteSourceSelect,
        getWasteBigTypeSelect,
        getWasteSmallTypeSelect,
        getAreaSelect,
        getYearSelect
    } from "@/api/common"
    import {
        queryFeatureTableData,
        queryFeatureDetail
    } from "@/api/waste"
    
    export default {
        name: 'Data',
        props: {
            msg: String
        },
        data() {
            return {
                input: '',
                
                view: {
                    detailVisible: false
                },
                dialogData: {},
                searchModel: {
                    searchValue: '',
                    sources: [],
                    wasteBigTypes: [],
                    wasteSmallTypes: [],
                    // years: []
                },
                
                sources: [],
                wasteBigType: [],
                wasteSmallType: [],
                area: [],
                years: [],
              
            
                tableData: [],
                total: 0,
                currentPage: 1,
                pageCount: 0,
                pageSizes: 20
                
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.queryWasteSourceSelect()
                this.queryAreaSelect()
                this.queryYearSelect()
                this.selectData()
            },
            queryAreaSelect() {
                getAreaSelect().then(data => {
                    this.area = data
                })
            },
            queryYearSelect() {
                getYearSelect().then(data => {
                    this.years = data
                })
            },
             // 固废来源
            clickCategory (item,index){
                this.categoryIndex = index
                console.log(this.categoryIndex,"4546")
                item.active = !item.active
                // debugger
                this.searchModel.sources.push(item.value)
                // console.log(this.searchModel.sources.push(item.value),"454654654")
                this.queryWasteBigSelect()
            },
            // 固废大类
            clickbig(item,index){
                this.categoryIndex = index
                item.active1= !item.active1
                this.searchModel.wasteBigTypes.push(item.value)
                this.queryWasteSmallSelect()
            },
            // 固废小类
            smallCK(item,index) {
                this.categoryIndex = index
                item.active2= !item.active2
                    
                this.searchModel.wasteSmallTypes.push(item.value)
                this.queryProductSmallSelect()
            },
            // 固废来源
            queryWasteSourceSelect() {
                getWasteSourceSelect().then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active = false
                    }
                    this.sources = data
                })
            },
            // 固废大类
            queryWasteBigSelect() {
                if (this.searchModel.sources.length === 0) return
                getWasteBigTypeSelect(this.searchModel.sources).then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active1 = false
                    }
                    this.wasteBigType = data
                })
            },
            // 固废小类
            queryWasteSmallSelect() {
                if (this.searchModel.wasteBigTypes.length === 0) return
                getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active2 = false
                    }
                    this.wasteSmallType = data
                })
            },
            
            
            
            
            
            queryTableData(searchParams, page) {
                if (!searchParams) {
                    searchParams = this.searchModel
                }
                queryFeatureTableData(searchParams, page).then(data => {
                    this.tableData = data.records
                    console.log(this.tableData,"45400")
                    this.total = data.total
                    this.currentPage = data.current
                    this.pageSizes = data.size
                    this.pageCount = data.pages
                })
            },
            selectData() {
                this.queryTableData()
            },
            handleCurrentChange(val) {
                const page = {
                    pageNo: val,
                    pageSize: this.pageSizes
                }
                this.queryTableData(this.searchModel, page)
            },
            viewDetail(id) {
                // debugger
                if (id === null || id === undefined || id === '') {
                    return
                }
                queryFeatureDetail(id).then(data => {
                    this.dialogData = data
                    this.view.detailVisible = true
                })
            },
            toSearch() {
                // eslint-disable-next-line no-debugger
                // debugger
                if (this.keyword === '') {
                    ui.toast(this, ui.variant.primary, '请输入搜索内容')
                    return;
                }
                if (this.$store.getters.token === '') {
                    ui.toast(this, ui.variant.primary, '请先登录账号')
                    return;
                }
                this.$router.push('/search/' + this.keyword)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    @import "../../assets/less/data.less";
    .newBox {
        height: 1400px;
    }
    .active {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .active1 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .active2 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .headerBox {
        width: 100%;
        height: 9%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DDDDDD;

        .headerLeft {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1A1A1A;

            .boxStyle {
                width: 7px;
                height: 22px;
                background: #007EFF;
                border-radius: 4px;
                margin-right: 5%;
            }
        }
        .searchBox {
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1A1A1A;
            .searchStyle {
                width: 20%;
                height: 31%;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                background: #007EFF;
            }
            .searchWidth {
                width: 40%;
                el-input{
                    display: none;
                }
            }
        }
    }
    .centerSelect {
        width: 100%;
        height: 20%;
        margin-top: 5%;
        display: flex;
        justify-content: space-between;
        .leftSelectBox {
            width: 30%;
            height: 100%;
            border-bottom: 1px solid #DDDDDD;
            .SelectTopBox{
                width: 100%;
                height: 15%;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #1A1A1A;
            }
            .blackStyle {
                margin: 5% 0;
                width: 40px;
                height: 4px;
                background: #353535;
                border-radius: 2px;
            }
            .SelectListBox {
                width: 100%;
                height: 75%;
                margin-top: 5%;
                overflow-y: auto;
                .SelectListItem {
                    width: 100%;
                    height: 10%;
                    margin-bottom: 5%;
                    display: flex;
                    cursor: pointer;
                }
                .gouImg {
                    width: 17px;
                    height: 13px;
                    img {
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                    }
                }
                .itemText {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    // color: #333;
                    margin-left: 4%;
                }
            }
            
        }
    }
    .emptyAndInquire {
        width: 100%;
        height: 3%;
        margin-top: 2%;
        display: flex;
        justify-content: center;
        cursor: pointer;
        .qKBox {
            width: 10%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .qKImg {
                width: 18px;
                height: 18px;
                margin-right: 5%;
                img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }
            .qkTEXT {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                margin-top: 4%;
                color: #007EFF;
            }
        }
        .InquireBOX{
            width: 11%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(270deg, #0F69DE, #0090FF);
            border-radius: 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FEFEFE;
            .qKImg {
                width: 18px;
                height: 19px;
                margin-right: 5%;
                img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }
            .cXTEXT {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FEFEFE;
                margin-top: 4%;
            }
        }
    }
    
    .CXresult {
        width: 100%;
        height: 10%;
        margin-top: 5%;
        text-align: center;
        padding: 2% 0;
        box-sizing: border-box;
        .EnglishText {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
           
        }
        .resultText {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1A1A1A;
        }
    }
    
    .resultListBox {
        width: 100%;
        height: 30%;
        .resultListTop {
            width: 100%;
            height: 15%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #F9F9FA;
            border: 1px solid #EEEEEE;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #555555;
        }
        .resultListCenter{
            width: 100%;
            height: 70%;
            overflow-y: auto;
            border: 1px solid #EEEEEE;
            background-color: #FFFFFF;
           .resultListCenterItem {
               width: 100%;
               height: 25%;
               display: flex;
               align-items: center;
               justify-content: space-around;
               border-bottom: 1px solid #EEEEEE;
               font-size: 18px;
               font-family: PingFang SC;
               font-weight: 500;
               color: #000000;
               .textStyletwo {
                   font-size: 18px;
                   font-family: PingFang SC;
                   font-weight: 500;
                   color: #409EFF;
                   cursor: pointer;
               }
           }
        }
        .resultListButtom {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
        }
    }
    
    .resultListCenter::-webkit-scrollbar {
        width: 8px !important;
        height: 100% !important;
        background: #EEEEEE;
        border-radius: 3px;
    }
    .resultListCenter::-webkit-scrollbar-thumb {
        width: 8px !important;
        height: 50% !important;
        background: #007EFF;
        border-radius: 3px;
    }
    .SelectListBox::-webkit-scrollbar {
        width: 8px !important;
        height: 100% !important;
        background: #EEEEEE;
        border-radius: 3px;
    }
    .SelectListBox::-webkit-scrollbar-thumb {
        width: 8px !important;
        height: 50% !important;
        background: #007EFF;
        border-radius: 3px;
    }
</style>
